import { ComponentRef, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { PermissionService } from '../permission/permission.service';
import { AuthService } from '../auth/auth.service';
import { Endpoints } from 'src/app/constants/endpoints';
import { HttpClient } from '@angular/common/http';
import { catchError, map, take } from 'rxjs/operators';
import { BussionFilterDefinition } from 'src/app/models/responses/dataStoreResponse';
import { ModalService } from '../modal/modal.service';
import { CreateUserComponent } from 'src/app/pages/manage/user/create-user/create-user.component';
import { User } from 'src/app/models/responses/loginResponse';
import { SharePreviewComponent } from 'src/app/pages/share-preview/share-preview.component';
import { MainComponent } from 'src/app/pages/main/main.component';
import { UserComponent } from 'src/app/pages/manage/user/user.component';
import { dashboardOptions } from 'src/app/models/globals/dashboardOptions';
import { SharePreviewModalComponent } from 'src/app/components/share-preview-modal/share-preview-modal.component';
import { Dashboard } from 'src/app/models/responses/dashboardResponse';
import { DashboardService } from '../dashboard/dashboard.service';
import { ShareService } from '../share/share.service';
import { Router } from '@angular/router';
import { Widget } from 'src/app/models/responses/widgetLibraryResponse';
import { DeviceDetectorService } from 'ngx-device-detector';


@Injectable({
  providedIn: 'root'
})
export class ScriptLoaderService {

  private uploadedFiles: string[] = [];
  private didNewFileLoad: boolean = false;
  filterChanges: BehaviorSubject<BussionFilterDefinition[]> = new BehaviorSubject<BussionFilterDefinition[]>([]);
  selectedFilters: BehaviorSubject<BussionFilterDefinition[]> = new BehaviorSubject<BussionFilterDefinition[]>([]);
  modalFilters: BehaviorSubject<BussionFilterDefinition[]> = new BehaviorSubject<BussionFilterDefinition[]>([]);
  runnedWidgetCode: BehaviorSubject<string> = new BehaviorSubject<string>('');

  openedModals: BehaviorSubject<{ modal: number, filter: BussionFilterDefinition[], widgets: any[] }[]> = new BehaviorSubject<{ modal: number, filter: BussionFilterDefinition[], widgets: any[] }[]>([]);


  changedConfigs: BehaviorSubject<{ configtype: string, config: any }> = new BehaviorSubject<{ configtype: string, config: any }>({ configtype: '', config: '' });


  _selectedTabForClick: BehaviorSubject<string> = new BehaviorSubject<string>('');
  _paletColors: BehaviorSubject<{ paletId: string, color: string, key: string }[]> = new BehaviorSubject<{ paletId: string, color: string, key: string }[]>([]);



  navigateToDashboard: BehaviorSubject<any> = new BehaviorSubject<any>({});
  selecteddashboard: Dashboard
  selectedTabForClick: string = ''
  paletColors: { paletId: string, color: string, key: string }[] = []

  constructor(
    private dashboardService: DashboardService,
    private http: HttpClient,
    private shareService: ShareService,
    private authService: AuthService,
    private modalService: ModalService,
    private router: Router,
    private device: DeviceDetectorService
  ) {


  }

  addScriptFileToHead(scripts: string[]) {
    scripts.forEach(url => {
      if (url.includes(".js")) {
        this.addJSFile(url);
      }
      else if (url.includes(".css")) {
        this.addCSSFile(url);
      }
      else { }
    });
  }

  private addJSFile(fileURL: string) {
    if (this.isFileExist(fileURL)) { return };
    let script = document.createElement('script');
    script.src = environment.imageUrl + fileURL;
    script.type = 'text/javascript';
    script.async = false;
    script.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(script);
  }

  private addCSSFile(fileURL: string) {
    if (this.isFileExist(fileURL)) { return };
    var link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = environment.imageUrl + fileURL;
    link.media = 'all';
    document.getElementsByTagName('head')[0].appendChild(link);
  }

  private isFileExist(url: string) {
    let urlItems = url.split('/');
    let lastIindex = url.split('/').length - 1;
    let fileName = urlItems[lastIindex];
    this.didNewFileLoad = this.uploadedFiles.includes(fileName);
    if (!this.didNewFileLoad) {
      this.uploadedFiles.push(fileName);
    }
    return this.didNewFileLoad;
  }

  changeWidgetConfig(config, configtype) {

    this.changedConfigs.next({ configtype: configtype, config: config[configtype] })

  }


  runWidgetCode(widgetResponse: any, refresh?) {

    this.selecteddashboard = this.dashboardService.selectedDasboard.getValue()
    this._selectedTabForClick.subscribe(res => this.selectedTabForClick = res)
    this._paletColors.subscribe(res => this.paletColors = res)
    let timeout = this.didNewFileLoad ? 1000 : 1000;
    let user = this.authService.getUser();
    var refreshSend = refresh;
    setTimeout(() => {
      var runScriptCode = new Function('data', 'refresh', 'widget', 'dashboard', 'user', widgetResponse.code);
      runScriptCode(widgetResponse.widgetData, refreshSend, widgetResponse.widget, this, user);
      this.runnedWidgetCode.next(widgetResponse.widgetId)
    }, timeout);
  }

  runwidgetCodeForCompiler(widgetResponse: any, refresh?) {

    let user = this.authService.getUser();
    var refreshSend = refresh == undefined ? false : true
    var runScriptCode = new Function('data', 'refresh', 'widget', 'dashboard', 'user', widgetResponse.code);
    runScriptCode(widgetResponse.widgetData, refreshSend, widgetResponse.widget, this, user);
  }


  setFilter(filters: any[], rerender?) {
    // if (rerender) {
    console.log('burdayım2', filters)
    this.filterChanges.next(filters);
    this.selectedFilters.next(filters)
    // } else {
    //   this.selectedFilters.next(filters)

    //   this.dashboardService.getWidgetsForDashboard(this.selecteddashboard.dashboardId, filters).subscribe((res) => {
    //     res.map(item => {
    //       // if (!item.widget.type.toLowerCase().includes('filter'))
    //     //  document.getElementById(item.widgetId).innerHTML=''
    //       this.runWidgetCode(item)
    //     })

    //   })
    // }
  }

  SetFilterForDashboard(dashboardid: string, filters: BussionFilterDefinition[]) {
    this.dashboardService.getWidgetsForDashboard(dashboardid, filters).subscribe(res => {
      res.map(item => {
        this.runWidgetCode(item)
      })

    })
  }


  screenOptions: any = {
    width: '',
    height: '',
    position: '',
    isapp: 'false'
  }
  url: string = ''


  async setParam(Key: string, Value: string) {
    var Token = this.authService.getToken();
    var rst = await this.setParamInternal(Token, Key, Value);
    return rst;
  }

  async setParamInternal(Token: string, Key: string, Value: string) {
    const payload = { Token: Token, Key: Key, Value: Value };
    return this.http.post(Endpoints.setParam, payload).toPromise();
  }



  async getParam(Key: string) {
    let param = { "Token": this.authService.getToken(), "Key": Key };
    return await this.http.post(Endpoints.getParam, param).toPromise().
      then(async (res: any) => {
        // await this.atama(res);
        return res.message
      })

  }


  async deleteParam(Key: string) {
    const Token = this.authService.getToken()
    var rst = await this.deleteParamInternal(Token, Key)
    return rst;
  }
  async deleteParamInternal(Token: string, Key: string) {

    const payload = { Token: Token, Key: Key };
    this.http.post(Endpoints.deleteParam, payload).subscribe(
      (response: any) => {
        const value = response.Value;
        return true;
      },
      (error) => {
        console.error('Bir hata oluştu.');
      }
    );
  }

  async openWindow(url: string, options: {
    type: string;
    windowWidth: string;
    windowHeight: string;
    isapp: string;
    app?: any;
    clickedWidget?: Widget
  }) {
    options.isapp = options.isapp ? options.isapp : 'false'
    this.url = url
    let type = ''
    this.screenOptions.position = options?.type?.toLowerCase()
    this.screenOptions.isapp = options?.isapp ? options?.isapp : 'false'
    let position;



    if (this.device.isMobile()) {
      options.windowHeight = '100%'
      options.windowWidth = '100%'

    } else if (this.device.isTablet() && screen.orientation.type.includes('landscape') && parseInt(options.windowWidth?.split('%')[0]) < 60) {
      options.windowWidth = '60%'
    } else if (this.device.isTablet() && screen.orientation.type.includes('portrait')) {
      options.windowWidth = '100%'

    }


    if (options) {
      if (options?.type.toLowerCase() == 'newwindow') {
        var a = document.createElement('a');
        a.href = '/#/share' + url;
        a.setAttribute('target', '_blank');
        a.click();
      } else {
        // this.dashboardOptions.shareKey = options.shareKey
        // this.dashboardOptions.domain = options.domain
        // this.dashboardOptions.filters = JSON.parse(options.filters)
        if (!this.url?.split('/')[5] && options?.isapp?.toString() == 'false') {
          this.url = environment.baseUrl + '/#/share' + url
        }
        this.screenOptions.width = options.windowWidth
        this.screenOptions.height = options.windowHeight
        if (options?.type.toLowerCase() == 'centerwindow') {
          type = 'center'

        } else if (options?.type.toLowerCase() == 'rightwindow') {
          type = 'rightSideBar'
          position = {
            right: '0'
          }
        } else if (options?.type.toLowerCase() == 'leftwindow') {
          type = 'leftSideBar'
          position = {
            left: '0'
          }
        }


        if (options?.isapp?.toString() == 'false') {
          var dashboard = await this.getShareObject()

          if (this.selecteddashboard?.dashboardId == dashboard.dashboardId && options?.type == 'selfWindow') {
            if (this.url.split('filters=')[1]) {
              var dashboardfilter = JSON.parse(this.url.split('filters=')[1])
              this.setFilter(dashboardfilter)
            }
            return;
          }

          const currentModals = this.openedModals.getValue();
          var currentIndex = currentModals.length > 0 ? currentModals[currentModals.length - 1].modal + 1 : 1

          var currentModalFilter = []
          if (this.url.includes('filters=')) {
            currentModalFilter = JSON.parse(this.url.split('filters=')[1])
          }


          this.changeModalList(currentModalFilter, currentIndex)


          this.modalService.openModal({ exitButton: true, showAvatar: true, dashboard: dashboard, clickedWidget: options.clickedWidget, currentModalIndex: currentIndex, window: { height: options.windowHeight, width: options.windowWidth } }, SharePreviewComponent, this.screenOptions.height, this.screenOptions.width, type, position).subscribe(result => {
            this.deleteCurrentFilterOnCloseModal(options.clickedWidget, currentModalFilter)
            this.openedModals.getValue().pop()
          });
        } else {
          if (options.app.appType == 0 && options.app.openerMethod == 2) {
            this.router.navigate(['data-app/' + options.app.applicationId])
          } else {
            this.modalService.openModal(options, SharePreviewModalComponent, this.screenOptions.height, this.screenOptions.width, type, position).subscribe(result => {
            });
          }
        }

      }
    } else {
      // let a = document.createElement('a');
      // a.target = '_blank';
      // a.href = url;
      // a.click();
      this.screenOptions.width = '100%'
      this.screenOptions.height = '100%'
      type = 'center'
      position = {
        left: '0'
      }
      this.modalService.openModal({ exitButton: true, showAvatar: true }, SharePreviewComponent, this.screenOptions.height, this.screenOptions.width, type, position).subscribe(result => {
      });
    }
  }

  changeModalList(modalfilter: BussionFilterDefinition[], modalIndex: number, widgets = []) {
    const currentModals = this.openedModals.getValue(); // Mevcut modalleri al
    currentModals.push({ modal: modalIndex, filter: modalfilter, widgets: widgets }); // Yeni modalı ekle
    this.openedModals.next(currentModals);
  }

  deleteCurrentFilterOnCloseModal(clickedWidget: Widget, currentModalFilters: BussionFilterDefinition[]) {
    // if (this.dialogRef)
    //   this.dialogRef.afterClosed().subscribe(res => {
    console.log(currentModalFilters)
    if (clickedWidget && clickedWidget.drillDown.dashboardFilter) {
      var filters = this.modalFilters.getValue()
      currentModalFilters.map(item => {
        if (filters.some(x => x.fieldName == item.fieldName && x.fieldValue == item.fieldValue)) {
          filters = filters.filter(x => !(x.fieldName == item.fieldName && x.fieldValue == item.fieldValue))
        }
      })
      this.modalFilters.next(filters)
    }
    console.log('kalan', this.modalFilters.getValue())
    // })
  }

  getShareObject() {
    var shareKey = this.url?.split('/')[5]
    if (shareKey.includes('?')) {
      shareKey = shareKey.split('?')[0]
    }
    return new Promise<any>((resolve, reject) => {
      this.shareService.getShareObject(shareKey).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }



  sendLog(type: string, message: string) {
    let params = {
      Token: this.authService.getToken(),
      Type: type,
      Message: message
    }
    this.http.post(Endpoints.sendAuditLogs, params).pipe(
      map((response: any) => {
        return response.message;
      })
    ).subscribe(r => { });
  }

  runScript(scriptName: string) {
    let params = {
      Token: this.authService.getToken(),
      BussionScript: scriptName
    }
    this.http.post(Endpoints.startScript, params).pipe(
      map((response: any) => {
        return response.message;
      })
    ).subscribe(r => { });
  }

  navigateDashboard(dashboardId: string, filters: Array<any>) {
    let mappedFilter = [];
    filters.forEach(f => {
      let filter = {
        fieldName: f.fieldName,
        operator: "=",
        fieldValue: f.fieldValue
      }
      mappedFilter.push(filter);
    });
    let data = {
      dashboardId: dashboardId,
      filters: mappedFilter
    }
    this.navigateToDashboard.next(data);
  }

  getDataWithOffset(widget: Widget, offset: number): Promise<any> {
    return new Promise((resolve, reject) => {
      widget.offSet = offset;
      this.dashboardService.widgetPreview(widget).subscribe(
        (res) => {
          resolve(res); // Başarılı olduğunda resolve edilir
        },
        (error) => {
          reject(error); // Hata oluştuğunda reject edilir
        }
      );
    });
  }



  runGlobalFunction(globalFunction: string) {
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.innerHTML = globalFunction;
    document.getElementsByTagName('head')[0].appendChild(script);
  }



}
