<div class="modal-form-container">
    <div class="modal-form-content">
        <mat-form-field>
            <mat-label>{{ 'FilterComponent.Field' | translate }}</mat-label>
            <input matInput *ngIf="fields.length<=0" [(ngModel)]="queryFilter.fieldName" />
            <mat-select *ngIf="fields.length>0" [(ngModel)]="queryFilter.fieldName"
                (ngModelChange)="filterFieldSelected($event)">
                <mat-option *ngFor="let field of fields" [value]="field.fieldName">
                    <mat-icon [svgIcon]="field.fieldType.split('.')[1]"></mat-icon>
                    {{field.fieldName}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <br>

        <mat-form-field *ngIf="fields.length<=0">
            <mat-label>{{ 'FilterComponent.Type' | translate }}</mat-label>
            <mat-select [(ngModel)]="selectedFilterType" (ngModelChange)="changeFieldType($event)">
                <mat-option value="System.String">{{ 'FilterComponent.String' | translate }}</mat-option>
                <mat-option value="System.DateTime">{{ 'FilterComponent.DateTime' | translate }}</mat-option>
                <mat-option value="System.Number">{{ 'FilterComponent.Number' | translate }}</mat-option>
            </mat-select>
        </mat-form-field>
        <br>

        <mat-form-field>
            <mat-label>{{ 'FilterComponent.Operator' | translate }}</mat-label>
            <mat-select [(ngModel)]="queryFilter.operator" (ngModelChange)="operatorSelected($event)">
                <mat-option *ngFor="let item of operatorOptions" [value]="item.value">{{
                    'FilterComponent.'+item.text | translate }}</mat-option>
                <!-- <mat-option value="=">{{ 'FilterComponent.Equal' | translate }}</mat-option>
                <mat-option value="!=">{{ 'FilterComponent.NotEqual' | translate }}</mat-option>
                <mat-option value=">">{{ 'FilterComponent.GreaterThan' | translate }}</mat-option>
                <mat-option value=">=">{{ 'FilterComponent.GreaterThanEqual' | translate }}</mat-option>
                <mat-option value="<">{{ 'FilterComponent.Little' | translate }}</mat-option>
                <mat-option value="<=">{{ 'FilterComponent.LittleEqual' | translate }}</mat-option>
                <mat-option *ngIf="selectedFilterType == 'System.DateTime'" value="dayof">{{ 'FilterComponent.DayOf'
                    | translate }}</mat-option>
                <mat-option value="starts" *ngIf="selectedFilterType != 'System.DateTime'">
                    {{'FilterComponent.StartsWith' | translate }}
                </mat-option>
                <mat-option value="ends" *ngIf="selectedFilterType != 'System.DateTime'">{{
                    'FilterComponent.EndsWith' | translate }}</mat-option>
                <mat-option value="in" *ngIf="selectedFilterType != 'System.DateTime'">{{
                    'FilterComponent.Contains' | translate }}</mat-option>
                <mat-option value="nin" *ngIf="selectedFilterType != 'System.DateTime'">{{
                    'FilterComponent.NotContains' | translate }}
                </mat-option>
                <mat-option value="empty">{{ 'FilterComponent.IsEmpty' | translate }}</mat-option>
                <mat-option value="nempty">{{ 'FilterComponent.NotEmpty' | translate }}</mat-option>
                <mat-option *ngIf="selectedFilterType == 'System.DateTime'" value="between">Between</mat-option>
                <mat-option *ngIf="selectedFilterType == 'System.DateTime'" value="today">Today</mat-option>
                <mat-option *ngIf="selectedFilterType == 'System.DateTime'" value="yesterday">Yesterday</mat-option>
                <mat-option *ngIf="selectedFilterType == 'System.DateTime'" value="thishour">This
                    Hour</mat-option>
                <mat-option *ngIf="selectedFilterType == 'System.DateTime'" value="thisweek">This
                    Week</mat-option>
                <mat-option *ngIf="selectedFilterType == 'System.DateTime'" value="thismonth">This Month
                </mat-option>
                <mat-option *ngIf="selectedFilterType == 'System.DateTime'" value="thisyear">This
                    Year</mat-option>
                <mat-option *ngIf="selectedFilterType == 'System.DateTime'" value="lasthour">Last
                    Hour</mat-option>
                <mat-option *ngIf="selectedFilterType == 'System.DateTime'" value="lastweek">Last
                    Week</mat-option>
                <mat-option *ngIf="selectedFilterType == 'System.DateTime'" value="lastmonth">Last Month
                </mat-option>
                <mat-option *ngIf="selectedFilterType == 'System.DateTime'" value="lastyear">Last
                    Year</mat-option>
                <mat-option *ngIf="selectedFilterType == 'System.DateTime'" value="beforendays">Before N Days
                </mat-option>
                <mat-option *ngIf="selectedFilterType == 'System.DateTime'" value="lastnadays">Last N Days
                </mat-option>
                <mat-option *ngIf="selectedFilterType == 'System.DateTime'" value="lastnhours">Last N Hours
                </mat-option>
                <mat-option *ngIf="selectedFilterType == 'System.DateTime'" value="lastnminutes">Last N Minutes
                </mat-option> -->
            </mat-select>
        </mat-form-field>
        <br>

        <div class="field-value-form" *ngIf="isShownFieldValueForm">
            <mat-form-field *ngIf="queryFilterFieldType == 'System.String'">
                <mat-label>{{ 'FilterComponent.Value' | translate }}</mat-label>
                <input matInput [(ngModel)]="queryFilter.fieldValue" type="text" [matAutocomplete]="auto"
                    (ngModelChange)='searchFilter($event)' required>

                <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let option of searchValues" [value]="option">
                        {{option}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>

            <mat-form-field *ngIf="queryFilterFieldType == 'System.Number'">
                <mat-label>{{ 'FilterComponent.Value' | translate }}</mat-label>
                <input matInput [(ngModel)]="queryFilter.fieldValue" type="number" [matAutocomplete]="auto" required>

                <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let option of searchValues" [value]="option">
                        {{option}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>

            <mat-form-field *ngIf="queryFilterFieldType == 'System.DateTime'&&queryFilter.operator!=='between'">
                <mat-label>{{ 'FilterComponent.Value' | translate }}</mat-label>
                <input matInput #dateInput [matDatepicker]="picker" (dateInput)="dateSelected($event,false)" required>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

            <mat-form-field class=" date-range-field"
                *ngIf="queryFilterFieldType == 'System.DateTime'&&queryFilter.operator=='between'">
                <mat-label>{{ 'FilterComponent.Value' | translate }}</mat-label>
                <mat-date-range-input [rangePicker]="picker">
                    <input matStartDate matInput matStartDate [placeholder]="'FilterComponent.StartDate'|translate"
                        (dateInput)="dateSelected($event,true,'start')">
                    <input matEndDate matInput matEndDate [placeholder]="'FilterComponent.EndDate'|translate"
                        (dateInput)="dateSelected($event,true,'end')">
                </mat-date-range-input>
                <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
            <br>
        </div>
        <br>
    </div>
    <div class="modal-form-action-content">
        <button mat-button class="b-btn" *ngIf="!clearButton">{{ 'Common.Cancel' | translate
            }}</button>
        <button mat-button class="b-btn" (click)="clearClick()"
            *ngIf="clearButton&&(queryFilter.fieldName||queryFilter.fieldValue||queryFilter.operator)">{{ 'Common.Clear'
            |
            translate }}</button>

        <button mat-button class="b-btn b-success" cdkFocusInitial (click)="addFilter()">{{ 'Common.Add' | translate
            }}</button>
    </div>
</div>