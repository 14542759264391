<div id="filter-content" class="filter-content" [ngStyle]="{'top': isHeaderShown ? '0px' : '70px' }">
    <div style="align-items: center;justify-content: space-between;display: flex;margin-bottom: 1rem;">
        <div style="display: flex;align-items: center;">
            <h2 style="margin:0;color: #5E6DA6;">{{"FilterComponent.Title"|translate}}</h2>
            <mat-slide-toggle style="margin-left: 20px;" *ngIf="manage" [checked]="hideButtonActive"
                (change)="hidePanel($event)">{{
                'FilterComponent.HidePanel' |translate }}</mat-slide-toggle>
        </div>
        <div style="display: flex;align-items: center;">

            <button (click)="saveDimensionSorting() " *ngIf="manage" mat-stroked-button
                color="primary">{{dimensiondragactive?( 'FilterComponent.saveSort' |
                translate):('FilterComponent.changeSort' | translate)}}
                <mat-icon [svgIcon]="'sort'"
                    style="transform: scale(0.5);width: 40px !important;height: 40px !important"></mat-icon>
            </button>

            <mat-icon class="close-icon" (click)="closeFilter()">clear</mat-icon>
        </div>
    </div>

    <div class="dimension-content" (cdkDropListDropped)="drop($event,'selectbox')" cdkDropList
        [style.FlexDirection]="dimensiondragactive?'column':'row'" [cdkDropListDisabled]="!manage">
        <div class="dimension-box" *ngFor="let d of dimensions" cdkDrag>

            <mat-icon (click)="deleteDimension(d)" *ngIf="manage" matTooltipPosition="above"
                [matTooltip]="'WidgetButtons.Delete'|translate" svgIcon="delete-box" style="transform: scale(0.7);">
            </mat-icon>

            <mat-icon *ngIf="manage && dimensiondragactive" cdkDragHandle [svgIcon]="'draghand'"
                style="transform: scale(0.6);width: 30px;height: 30px;">
            </mat-icon>

            <mat-icon *ngIf="manage" (click)="openSelected(d.dimensionId)" matTooltipPosition="above"
                [matTooltip]="'FilterComponent.changeDimensionName'|translate" svgIcon="edit"
                style="transform: scale(0.7);"></mat-icon>

            <mat-icon *ngIf="manage" (click)="lockFilter(d)" matTooltipPosition="above"
                [matTooltip]="'FilterComponent.lockFilter'|translate" style="transform: scale(0.6);">{{d.lockedFilter?
                'lock':'lock_open'}}</mat-icon>

            <mat-icon *ngIf="manage" (click)="saveSortState(d)" matTooltipPosition="above"
                [matTooltip]="'FilterComponent.changeSort'|translate" [svgIcon]="d?.isOrdered==0? 'sort':'sort-green'"
                style="transform: scale(0.7);width: 30px;height: 30px;"></mat-icon>

            <mat-icon *ngIf="manage" (click)="d.lockedFilter?'':pinToPanel(d)" matTooltipPosition="above"
                [matTooltip]="'FilterComponent.pinToPanel'|translate" [svgIcon]="d?.pintoPanel==0? 'pin':'pin-green'"
                style="transform: scale(0.7);" [style.Cursor]="d.lockedFilter? 'not-allowed':''"></mat-icon>

            <mat-icon *ngIf="manage" (click)="disableSearch(d)" matTooltipPosition="above"
                [matTooltip]="'FilterComponent.DisableSearch'|translate" [svgIcon]="'disableSearch'"
                style="transform: scale(0.8);" [style.color]="d.allowSearch==1?'':'red'"></mat-icon>

            <mat-icon *ngIf="manage" (click)="showAllValues(d)" matTooltipPosition="above"
                [matTooltip]="'FilterComponent.ShowAllValues'|translate"
                [svgIcon]="d?.showAllValues==0? 'show-all':'show-all-green'" style="transform: scale(0.8);"></mat-icon>



            <div style="width:100%;display: flex;align-items: center;" [ngClass]="{'filter-exist': d.isFilterExist}"
                [matMenuTriggerFor]="selectionType" (click)="selectDimension(d)" (menuClosed)="closedMenu()">
                <span
                    *ngIf="!(selectedDimensionId!==''&& selectedDimensionId==d.dimensionId)">{{d.visibleName?d.visibleName:d.name}}</span>
                <mat-icon *ngIf="d.lockedFilter==1" style="transform: scale(0.5);">lock</mat-icon>
                <input (keyup.enter)="saveVisibleName($event,d)" (focusout)="saveVisibleName($event,d)" matInput
                    *ngIf="selectedDimensionId!==''&& selectedDimensionId==d.dimensionId&&manage"
                    [value]="d.visibleName"
                    style="background-color:rgb(212,212,212);border-radius: 0.3rem;border: none;padding-left: 0.3rem;width: 90%;"
                    #editinput>
            </div>
        </div>


    </div>

    <div *ngIf="!isDeviceMobile" class="button-group-content">
        <div class="btn-left">
            <button mat-stroked-button class="custom-primary-stroked-button" style="margin-left: 0;"
                (click)="resetFilterFunc()">{{ 'FilterComponent.Reset'
                | translate }}</button>
            <!-- <button mat-raised-button class="custom-primary-button mr-10" (click)="clearAllFilter()">{{
                'FilterComponent.ClearAll' |
                translate }}</button> -->
        </div>
        <div class="btn-right">
            <button mat-stroked-button class="custom-primary-stroked-button" (click)="closeFilter()">{{ 'Common.Cancel'
                | translate }}</button>
            <button mat-raised-button class="custom-primary-button mr-10" *ngIf="savebuttonvisible"
                (click)="openShareModal({ objectId: selectedDashboardId, objectType: 'Dashboard',filter:filters })">
                <mat-icon>save</mat-icon>
                {{ 'FilterComponent.Save' |translate }}</button>
            <button mat-raised-button class="custom-primary-button mr-10" (click)="makeQuery()">{{ 'Common.Update' |
                translate
                }}</button>
        </div>
    </div>

    <div *ngIf="isDeviceMobile " class="button-group-content_mobile">
        <!-- <button mat-button class="b-btn b-danger" (click)="clearAllFilter()">{{ 'FilterComponent.ClearAll' | translate
            }}</button> -->
        <button mat-button class="b-btn" (click)="resetFilterFunc()">{{ 'FilterComponent.Reset' | translate }}</button>
        <button mat-button class="b-btn" *ngIf="savebuttonvisible"
            (click)="openShareModal({ objectId: selectedDashboardId, objectType: 'Dashboard' })">{{ 'Common.Save' |
            translate }}</button>
        <button mat-button class="b-btn b-success" (click)="makeQuery()">{{ 'FilterComponent.Query' | translate
            }}</button>
    </div>
</div>

<mat-menu #selectionType="matMenu" class="mat-menu-class" style="width:30% !important">

    <div class="selection-mat-content" (click)="$event.stopPropagation();">
        <div *ngIf="isDimensionSelected&&!manage&&selectedDimension.lockedFilter==1&&selectedDimensionFilter.length<=0">
            {{"FilterComponent.EmptyFilter"|translate}}
        </div>

        <div class="filter-selection-form"
            *ngIf=" (isDimensionSelected&&!manage&&selectedDimension.lockedFilter==0)||(manage&&isDimensionSelected)">
            <mat-form-field>
                <mat-label>{{ 'FilterComponent.Operator' | translate }}</mat-label>
                <mat-select [(ngModel)]="filter.operator" (ngModelChange)="operatorSelected($event)">
                    <mat-option *ngFor="let item of operatorOptions" [value]="item.value">{{
                        'FilterComponent.'+item.text | translate }}</mat-option>
                    <!-- <mat-option value="=">{{ 'FilterComponent.Equal' | translate }}</mat-option>
                    <mat-option value="!=">{{ 'FilterComponent.NotEqual' | translate }}</mat-option>
                    <mat-option value=">">{{ 'FilterComponent.GreaterThan' | translate }}</mat-option>
                    <mat-option value=">=">{{ 'FilterComponent.GreaterThanEqual' | translate }}</mat-option>
                    <mat-option value="<">{{ 'FilterComponent.Little' | translate }}</mat-option>
                    <mat-option value="<=">{{ 'FilterComponent.LittleEqual' | translate }}</mat-option>
                    <mat-option *ngIf="selectedDimension.type == 'System.DateTime'" value="dayof">{{
                        'FilterComponent.DayOf' | translate }}</mat-option>
                    <mat-option value="starts" *ngIf="selectedDimension.type != 'System.DateTime'">
                        {{'FilterComponent.StartsWith' | translate }}
                    </mat-option>
                    <mat-option value="ends" *ngIf="selectedDimension.type != 'System.DateTime'">{{
                        'FilterComponent.EndsWith' | translate }}</mat-option>
                    <mat-option value="in" *ngIf="selectedDimension.type != 'System.DateTime'">{{
                        'FilterComponent.Contains' | translate }}</mat-option>
                    <mat-option value="nin" *ngIf="selectedDimension.type != 'System.DateTime'">{{
                        'FilterComponent.NotContains' | translate }}
                    </mat-option>
                    <mat-option value="empty">{{ 'FilterComponent.IsEmpty' | translate }}</mat-option>
                    <mat-option value="nempty">{{ 'FilterComponent.NotEmpty' | translate }}</mat-option>
                    <mat-option *ngIf="selectedDimension.type == 'System.DateTime'" value="between">Between</mat-option>
                    <mat-option *ngIf="selectedDimension.type == 'System.DateTime'" value="today">Today</mat-option>
                    <mat-option *ngIf="selectedDimension.type == 'System.DateTime'"
                        value="yesterday">Yesterday</mat-option>
                    <mat-option *ngIf="selectedDimension.type == 'System.DateTime'" value="thishour">This
                        Hour</mat-option>
                    <mat-option *ngIf="selectedDimension.type == 'System.DateTime'" value="thisweek">This
                        Week</mat-option>
                    <mat-option *ngIf="selectedDimension.type == 'System.DateTime'" value="thismonth">This Month
                    </mat-option>
                    <mat-option *ngIf="selectedDimension.type == 'System.DateTime'" value="thisyear">This
                        Year</mat-option>
                    <mat-option *ngIf="selectedDimension.type == 'System.DateTime'" value="lasthour">Last
                        Hour</mat-option>
                    <mat-option *ngIf="selectedDimension.type == 'System.DateTime'" value="lastweek">Last
                        Week</mat-option>
                    <mat-option *ngIf="selectedDimension.type == 'System.DateTime'" value="lastmonth">Last Month
                    </mat-option>
                    <mat-option *ngIf="selectedDimension.type == 'System.DateTime'" value="lastyear">Last
                        Year</mat-option>
                    <mat-option *ngIf="selectedDimension.type == 'System.DateTime'" value="beforendays">Before N Days
                    </mat-option>
                    <mat-option *ngIf="selectedDimension.type == 'System.DateTime'" value="lastnadays">Last N Days
                    </mat-option>
                    <mat-option *ngIf="selectedDimension.type == 'System.DateTime'" value="lastnhours">Last N Hours
                    </mat-option>
                    <mat-option *ngIf="selectedDimension.type == 'System.DateTime'" value="lastnminutes">Last N Minutes
                    </mat-option> -->
                </mat-select>
            </mat-form-field>

            <div class="example-form" *ngIf="isShownFieldValueForm">
                <mat-form-field style="width: 100%;"
                    *ngIf="queryFilterFieldType !== 'System.DateTime'&&queryFilterFieldType!=='System.Number'">
                    <mat-label>{{ 'FilterComponent.Value' | translate }}</mat-label>
                    <div *ngIf="selectedDimension.allowSearch">


                        <input *ngIf="options.length<=500" matInput type="text" [(ngModel)]="filter.fieldValue"
                            [formControl]="autoComplateControl" [matAutocomplete]="auto">

                        <input *ngIf="options.length>500" matInput type="text" [(ngModel)]="filter.fieldValue"
                            (keyup.enter)="searchDimensionValue()" [formControl]="newDimensionValue"
                            [matAutocomplete]="auto">

                        <mat-autocomplete #auto="matAutocomplete">
                            <div style="display: flex;padding: 5px;" *ngIf="manage">
                                <div class="add-element-input">
                                    <input matInput [formControl]="newDimensionValue"
                                        (keyup.enter)="searchDimensionValue()" (keydown)="$event.stopPropagation()">
                                </div>
                                <!-- <button mat-button (click)="addDimensionValue()"> -->
                                <mat-icon *ngIf="options.length<=500" class="c-pointer"
                                    [matTooltip]="'Common.Search'|translate"
                                    (click)="searchDimensionValue()">search</mat-icon>
                                <mat-icon (click)="addDimensionValue()">add</mat-icon>

                                <!-- </button> -->
                            </div>

                            <div *ngIf="isDimensionSelected" cdkDropList (cdkDropListDropped)="drop($event,'option')"
                                [cdkDropListDisabled]="!manage||selectedDimension.isOrdered!==1">
                                <ng-container *ngIf="(filteredOptions | async).length<=500;else tooManyOptions">
                                    <mat-option *ngFor="let v of filteredOptions | async" [value]="v" cdkDrag
                                        [cdkDragDisabled]="selectedDimension.isOrdered!==1||!manage">
                                        <div style="display: flex;align-items: center;justify-content: space-between;">
                                            <span *ngIf="selectedDimension.isOrdered==1" cdkDragHandle>
                                                <mat-icon *ngIf="manage" [svgIcon]="'draghand'"
                                                    style="transform: scale(0.5);width: 30px;height: 30px;">
                                                </mat-icon>
                                            </span>
                                            <div class="mat-option-text" [matTooltipPosition]="position.value!"
                                                [matTooltip]="v" style="width: 80%;">
                                                {{v}}
                                            </div>
                                            <mat-icon *ngIf="manage" svgIcon="delete-box"
                                                (click)="$event.stopPropagation();deleteDimensionValue(v)"
                                                style="transform: scale(0.6);margin: 0 !important;"></mat-icon>
                                        </div>

                                    </mat-option>
                                </ng-container>
                                <ng-template #tooManyOptions>
                                    <mat-option style="color: red; text-align: center; padding: 16px; font-size: smaller;white-space: normal;line-height: 1.2;">
                                        {{'FilterComponent.TooManyOptions'|translate}}
                                    </mat-option>
                                </ng-template>
                            </div>


                            <!-- <div *ngIf="isDimensionSelected&&selectedDimension.isOrdered==1" cdkDropList
                                (cdkDropListDropped)="drop($event,'option')" [cdkDropListDisabled]="!manage">
                                <mat-option [matTooltipPosition]="position.value!" [matTooltip]="v"
                                    *ngFor="let v of filteredOptions | async" [value]="v" cdkDrag>
                                    <span *ngIf="selectedDimension.isOrdered==1" cdkDragHandle>
                                        <mat-icon *ngIf="manage" [svgIcon]="'draghand'"
                                            style="transform: scale(0.5);width: 30px;height: 30px;">
                                        </mat-icon>
                                    </span>

                                    {{v}}
                                </mat-option>
                            </div> -->


                        </mat-autocomplete>
                    </div>
                    <div *ngIf="!selectedDimension.allowSearch">
                        <mat-select [(ngModel)]="filter.fieldValue" matNativeControl required>
                            <div style="display: flex;padding: 5px;" *ngIf="manage">
                                <div class="add-element-input">
                                    <input matInput [formControl]="newDimensionValue"
                                        (keyup.enter)="searchDimensionValue()" (keydown)="$event.stopPropagation()">
                                </div>
                                <!-- <button mat-button (click)="addDimensionValue()"> -->
                                <mat-icon class="c-pointer" [matTooltip]="'Common.Search'|translate"
                                    (click)="searchDimensionValue()">search</mat-icon>
                                <mat-icon class="c-pointer" (click)="addDimensionValue()"
                                    [matTooltip]="'FilterComponent.AddDimension'|translate">add</mat-icon>

                                <!-- </button> -->
                            </div>
                            <div cdkDropList (cdkDropListDropped)="drop($event,'option')"
                                [cdkDropListDisabled]="!manage || selectedDimension.isOrdered!==1">
                                <ng-container *ngIf="(filteredOptions|async).length<=500;else tooManyOptions">
                                    <mat-option [matTooltipPosition]="position.value!"
                                        *ngFor="let v of filteredOptions | async" [value]="v" cdkDrag>
                                        <div style="display: flex;justify-content: space-between;align-items: center;">
                                            <div style="display: flex;align-items: center;">
                                                <span *ngIf="selectedDimension.isOrdered==1&&manage" cdkDragHandle>
                                                    <mat-icon *ngIf="manage" [svgIcon]="'draghand'"
                                                        style="transform: scale(0.5);width: 30px;height: 30px;">
                                                    </mat-icon>
                                                </span>
                                                <span [matTooltip]="v">{{v}}</span>
                                            </div>
                                            <mat-icon *ngIf="manage" style="margin: 0 !important;" svgIcon="delete-box"
                                                (click)="$event.stopPropagation();deleteDimensionValue(v)"
                                                style="transform: scale(0.6);"></mat-icon>
                                        </div>

                                    </mat-option>
                                </ng-container>
                                <ng-template #tooManyOptions>
                                    <mat-option style="color: red; text-align: center; padding: 16px; font-size: smaller;white-space: normal;line-height: 1.2;">
                                        {{'FilterComponent.TooManyOptions'|translate}}
                                    </mat-option>
                                </ng-template>
                            </div>
                        </mat-select>
                    </div>



                </mat-form-field>

                <mat-form-field style="width: 100%;" *ngIf="queryFilterFieldType == 'System.Number'">
                    <mat-label>{{ 'FilterComponent.Value' | translate }}</mat-label>
                    <input matInput [(ngModel)]="filter.fieldValue" type="number" required>
                </mat-form-field>

                <mat-form-field style="width: 100%;"
                    *ngIf="queryFilterFieldType == 'System.DateTime'&&filter.operator!=='between'">
                    <mat-label>{{ 'FilterComponent.Value' | translate }}</mat-label>
                    <input matInput #dateInput [matDatepicker]="picker" (dateInput)="dateSelected($event,false)"
                        required>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>

                <mat-form-field class="custom-form-field" style="width: 100%;"
                    *ngIf="queryFilterFieldType == 'System.DateTime'&&filter.operator=='between'">
                    <mat-label>{{ 'FilterComponent.Value' | translate }}</mat-label>
                    <mat-date-range-input [rangePicker]="picker">
                        <input matStartDate matInput matStartDate [placeholder]="'FilterComponent.StartDate'|translate"
                            (dateInput)="dateSelected($event,true,'start')">
                        <input matEndDate matInput matEndDate [placeholder]="'FilterComponent.EndDate'|translate"
                            (dateInput)="dateSelected($event,true,'end')">
                    </mat-date-range-input>
                    <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>


            </div>

            <button mat-button class="b-btn b-success" (click)="addFilter()">{{'Common.Add' | translate}}</button>
        </div>
        <div class="filter-item-table" *ngIf="selectedDimensionFilter.length != 0">
            <app-filter-list [data]="selectedDimensionFilter" [disableDelete]="selectedDimension.lockedFilter"
                (delete)="deleteFilter($event)"></app-filter-list>
        </div>

    </div>
</mat-menu>