<div class="filter-badge">
    <div style="display:flex;width: 100%;align-items: center;justify-content: space-between;">
        <div class="badge-text" [matMenuTriggerFor]="filterlist">
            <span matTooltip="{{this._selectedFilter.dimension.visibleName||this._selectedFilter.dimension?.name}}">
                {{this._selectedFilter.dimension.visibleName||this._selectedFilter.dimension?.name}}
            </span>
            <small *ngIf="this._selectedFilter.content.length>1||this._selectedFilter.content.length==0">
                {{this._selectedFilter.content.length}} of
                {{this._selectedFilter.dimension.value.length}}</small>
            <small *ngIf="this._selectedFilter.content.length==1">
                {{this._selectedFilter.content[0]?.fieldValue?this._selectedFilter.content[0]?.fieldValue:this._selectedFilter.content[0]?.operator}}</small>

        </div>
        <div *ngIf="this._selectedFilter.content.length>0&&!this._selectedFilter.dimension.lockedFilter"
            class="clearfilter" (click)="clearSelectedFilter(this._selectedFilter)">
            X
        </div>
    </div>
    <mat-progress-bar mode="determinate"
        [value]="(100*this._selectedFilter.content.length)/this._selectedFilter.dimension.value.length"></mat-progress-bar>
</div>
<mat-menu class="badgemenu" #filterlist="matMenu" (closed)="onMenuClosed($event)">
    <div *ngIf="this._selectedFilter.dimension.type.includes('String')">
        <div style="padding: 3px;padding-bottom: 8px;display: flex;align-items: center;">
            <div class="filtersearch-container" (click)="$event.stopPropagation();">
                <Input type="text" placeholder="search" (keydown)="$event.stopPropagation()"
                    (keyup)="$event.stopPropagation()" (input)="searchText($event)" class="filterserach"
                    #filterserach />
                <mat-icon>search</mat-icon>
            </div>

            <mat-icon [matTooltip]="'Common.Asc'|translate"
                (click)="sortType='desc';$event.stopPropagation();changeSort()" *ngIf="sortType=='asc'"
                svgIcon="sortasc" class="sort-icon selected-sort"></mat-icon>
            <mat-icon [matTooltip]="'Common.Desc'|translate"
                (click)="sortType='none';$event.stopPropagation();changeSort()" *ngIf="sortType=='desc'"
                svgIcon="sortdesc" class="sort-icon selected-sort"></mat-icon>
            <mat-icon [matTooltip]="'Common.Asc'|translate"
                (click)="sortType='asc';$event.stopPropagation();changeSort()" *ngIf="sortType=='none'"
                svgIcon="sortasc" class="sort-icon"></mat-icon>

        </div>

        <!-- <div mat-menu-item *ngFor="let filter of selectboxValue" class="filterMenuElement"
            [class]="getClasname(filter.name)"
            (click)="selectFilter(filter.name,getClasname(filter.name));$event.stopPropagation();filterserach.value=''">
            <span>{{filter.name}}</span>
            <span *ngIf="filter.operator">{{("FilterComponent."+filter.operator)|translate}}</span>
        </div> -->
    </div>

    <div *ngIf="this._selectedFilter.dimension.type.includes('Date')&&!this._selectedFilter.dimension.lockedFilter"
        class="date-container">
        <mat-date-range-input [rangePicker]="picker">
            <input matStartDate matInput [(ngModel)]="rangeStart" matStartDate
                [placeholder]="'FilterComponent.StartDate'|translate" [readonly]="true">
            <input matEndDate matInput [(ngModel)]="rangeEnd" matEndDate
                [placeholder]="'FilterComponent.EndDate'|translate" [readonly]="true">
        </mat-date-range-input>
        <mat-datepicker-toggle matIconSuffix [for]="picker">
            <mat-icon matDatepickerToggleIcon svgIcon="DateTime"></mat-icon>
        </mat-datepicker-toggle>
        <mat-date-range-picker #picker (closed)="dateSelected()"></mat-date-range-picker>
        <mat-icon style="transform: scale(1.27);" [svgIcon]="'select'" (click)="$event.stopPropagation()"
            [matMenuTriggerFor]="dateMenu" [matTooltip]="'FilterComponent.Operator'|translate"></mat-icon>
        <mat-menu #dateMenu="matMenu" class="maxh-40">
            <button [ngClass]="{'selected-element': item.selected}" mat-menu-item *ngFor="let item of dateOptions"
                (click)="$event.stopPropagation();dateSelected(item)">{{"FilterComponent."+item.text|translate}}</button>
        </mat-menu>
    </div>

    <div *ngIf="this._selectedFilter.dimension.type.includes('Number')" class="number-container">
        <input type="number" (click)="$event.stopPropagation()" (keyup.enter)="numberselected($event)"
            (focus)="$event.stopPropagation()" matInput placeholder="Value" [(ngModel)]="numberInput">
    </div>

    <div mat-menu-item *ngFor="let filter of selectboxValue" class="filterMenuElement"
        [class]="getClasname(filter.name)"
        (click)="selectFilter(filter,getClasname(filter.name));$event.stopPropagation();">
        <span class="filter-name" *ngIf="filter.name">{{filter.name}}</span>
        <span class="filter-operator" *ngIf="filter.operator">{{("FilterComponent."+filter.operator)|translate}}</span>
    </div>
</mat-menu>