import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { DataStore, BussionFilterDefinition, preparedStatement, Columns } from 'src/app/models/responses/dataStoreResponse';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataStoreService } from 'src/app/services/dataStore/data-store.service';
import { NodeService } from 'src/app/services/node/node.service';
import { Node } from 'src/app/models/responses/nodeResponse';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { CoreService } from 'src/app/services/core/core.service';

import { ModalService } from 'src/app/services/modal/modal.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-datastore-form',
  templateUrl: './datastore-form.component.html',
  styleUrls: ['./datastore-form.component.scss']
})
export class DatastoreFormComponent implements OnInit {

  pageForm: UntypedFormGroup;
  title: string = "";
  isShownQueryForm: boolean = false;
  nodes: Node[] = [];
  universes: string[] = [];
  collections: string[] = [];
  columns: any[] = [];
  searchValues = [];
  selectedNode: Node;
  visibleKey: boolean = false
  querylists = { blacklist: [], whitelist: [], preparedQueryList: [] }

  selectedItem: { statementId: string, query: string } = { statementId: '', query: '' }
  queryFilter: BussionFilterDefinition = {
    fieldName: '',
    fieldValue: '',
    operator: ''
  }

  selectedDataPreviewColumns: Columns[] = []
  sampleDataForDataStore = []
  showTable: boolean = false

  queryFilterFieldType: string = "System.String";

  compareFunctionForColumns = (o1: any, o2: any) => o1.fieldName === o2.fieldName;

  isCreateForm: boolean = true;
  dataStore: DataStore = {
    name: "",
    description: "",
    createdBy: "",
    creationDate: new Date(),
    node: "",
    nodeId: "",
    userName: "",
    password: "",
    universe: "",
    collection: "",
    refCollection: "",
    type: "",
    lastUpdatedTime: new Date(),
    domain: "",
    query: [],
    columns: [],
    apiEncrypted: 0,
    apiEnable: 1,
    apiKey: '',
    wbListingEnable: 0,
    whiteLists: [],
    blackLists: [],
    preparedStatementEnable: 0,
    preparedStatement: [],
    plainQueryEnabled: 0
  }

  @ViewChild('staticqueryid') private staticqueryidref: ElementRef;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DatastoreFormComponent>,
    private dataStoreService: DataStoreService,
    private nodeService: NodeService,
    private coreService: CoreService,
    private translate: TranslateService,
    private modalService: ModalService
  ) { }

  ngOnInit(): void {
    this.title = this.data.type == 'update' ? this.translate.instant("DataStoreFormPage.TitleUpdate") : this.translate.instant("DataStoreFormPage.TitleCreate");
    if (this.data.dataStore) {
      this.isCreateForm = false;
      this.dataStore = Object.assign({}, this.data.dataStore);
      this.getUniverse();
      this.getCollections();
      this.getColumns();
    }

    this.getNodes();

    this.pageForm = new UntypedFormGroup({
      name: new UntypedFormControl(this.dataStore.name, [Validators.required]),
    });

  }

  controlPreparedStatementId(statementId: string) {
    return this.dataStore.preparedStatement.some(x => x.statementId == statementId)
  }

  createPreparedStatementId(preparedstatement: preparedStatement) {
    //to-do
    var statementId = (Math.floor(1000 + Math.random() * 9000)).toString();
    if (this.controlPreparedStatementId(statementId)) {
      this.createPreparedStatementId(preparedstatement)
    } else {
      return { ...preparedstatement, statementId: statementId }
    }

  }

  private getNodes() {
    this.nodeService.getDataNodes().subscribe(result => {
      this.nodes = result;
      this.nodes.forEach(n => {
        if (n.nodeId == this.dataStore.nodeId) {
          this.selectedNode = n;
        }
      })
      // console.log(this.nodes,this.dataStore,this.selectedNode)

    });
  }

  nodeSelection(n: Node) {
    this.dataStore.nodeId = n.nodeId;
    this.dataStore.node = n.host;
    this.dataStore.collection = "";
    this.dataStore.universe = "";
    this.dataStore.columns = []

    this.universes = [];
    this.collections = [];
    this.columns = [];

    this.getUniverse();
  }

  private getUniverse() {
    this.dataStoreService.getUniverses(this.dataStore.nodeId).subscribe(result => {
      this.universes = result;
    });
  }

  universeSelection() {
    this.dataStore.collection = "";
    this.dataStore.columns = []
    this.collections = [];
    this.columns = [];

    this.getCollections();
  }

  private getCollections() {
    this.dataStoreService.getCollections(this.dataStore.nodeId, this.dataStore.universe).subscribe(result => {
      this.collections = result;
    });
  }

  collectionSelection() {
    this.dataStore.columns = []
    this.columns = [];

    this.getColumns();
  }

  private getColumns() {
    this.dataStoreService.getColumnsByNodeId(this.dataStore.nodeId, this.dataStore.universe, this.dataStore.collection).subscribe(result => {
      this.columns = result;
      if (!this.data.dataStore) {
        this.dataStore.columns = this.columns
      }
    });
  }

  upsertDataStore() {
    this.dataStore.whiteLists = this.querylists.whitelist.concat(this.dataStore.whiteLists)
    this.dataStore.blackLists = this.querylists.blacklist.concat(this.dataStore.blackLists)
    this.dataStore.preparedStatement = this.querylists.preparedQueryList.concat(this.dataStore.preparedStatement)

    if (this.pageForm.invalid) {
      this.coreService.showFormFillError();
      return;
    }
    this.dataStoreService.upsertDataStore(this.dataStore).subscribe(result => {
      this.dialogRef.close(true);
    });
  }

  check(event: string, property: string) {

    this.dataStore[property] = event ? 1 : 0

  }

  updatedList: any[] = []

  clearArea() {
    this.staticqueryidref.nativeElement.value = ''
    this.selectedItem = { statementId: '', query: '' }
  }

  addElement(event: any, listtype: string) {
    if (listtype == 'preparedQueryList') {
      if (this.selectedItem.statementId !== '') {
        this.dataStore.preparedStatement.find(x => x.statementId == this.selectedItem.statementId).query = event.target.value.replace('\n', '')
        event.target.value = ''
        this.updatedList.push(this.selectedItem.statementId)
        this.selectedItem = { statementId: '', query: '' }
      } else {
        var preparedstatement
        if (this.staticqueryidref.nativeElement.value && this.staticqueryidref.nativeElement.value !== '') {
          if (this.controlPreparedStatementId(this.staticqueryidref.nativeElement.value)) {
            this.modalService.openConfirm().subscribe(result => {

            });
            return
          }
          preparedstatement = { params: '', query: event.target.value.replace('\n', ''), statementId: this.staticqueryidref.nativeElement.value }
          this.staticqueryidref.nativeElement.value = ''
        }
        else
          preparedstatement = this.createPreparedStatementId({ params: '', query: event.target.value.replace('\n', ''), statementId: '' })

        this.querylists[listtype].push(preparedstatement)
        event.target.value = ''
      }

    } else {
      this.querylists[listtype].push(event.target.value.replace('\n', ''))
      event.target.value = ''
    }

  }

  editOldElement(item: { statementId: string, query: string }) {
    this.selectedItem = item
  }

  deleteElement(item: string, listtype: string) {
    this.querylists[listtype] = this.querylists[listtype].filter(x => x !== item)
  }

  deleteOldElement(item: string, listtype: string) {
    this.dataStore[listtype] = this.dataStore[listtype].filter(x => x !== item)
  }

  addFilter(filter: BussionFilterDefinition) {
    this.dataStore.query.push(filter);
    this.isShownQueryForm = false;
    this.queryFilter = {
      fieldName: '',
      fieldValue: '',
      operator: ''
    }
  }

 

  deleteQuery(query: any) {

    let index = this.dataStore.query.findIndex(x => {
      return x.fieldName === query.fieldName && x.operator === query.operator && x.fieldValue === query.fieldValue;
    });
    if (index !== -1) {
      this.dataStore.query.splice(index, 1);
      this.dataStore.query = this.dataStore.query;
    }

  }

  searchValuesForQueryFilter(searchText: string) {
    if (searchText.length < 2) { return };
    this.dataStoreService.getValuesForFieldByUniverse(this.dataStore.node, this.dataStore.universe, this.dataStore.collection, this.queryFilter.fieldName, searchText).subscribe(result => {
      this.searchValues = result;
    })
  }

  filterFieldSelected(fieldName: string) {
    this.queryFilter.fieldValue = '';
    this.queryFilter.operator = '';
    let column = this.dataStore.columns.find(c => c.fieldName == fieldName);
    this.queryFilterFieldType = column.fieldType;
  }

  dateSelected(date: MatDatepickerInputEvent<Date>) {
    this.queryFilter.fieldValue = date.value.toISOString();
  }

  notifyDs() {
    if (this.dataStore.dataStoreId)
      this.dataStoreService.notifyDatastore(this.dataStore.dataStoreId).subscribe(res => {
        if (res)
          this.coreService.showSuccessMessage(this.translate.instant('Common.Success'))
        else
          this.coreService.showErrorMessage(this.translate.instant('Common.Error'))

      })

  }


  getsampleData() {
    this.showTable = true
    if (this.selectedDataPreviewColumns.length <= 0 || this.sampleDataForDataStore.length <= 0) {
      this.dataStoreService.getSampleData(this.dataStore.dataStoreId).subscribe(sampleres => {
        this.dataStoreService.getColomnsForDataStore(this.dataStore.dataStoreId).subscribe(res => {
          this.selectedDataPreviewColumns = res
          this.sampleDataForDataStore = sampleres
        })
      })
    }

  }

}
