export const filter_options = [
    { text: 'Equal', value: '=', type: [], valueField: true },
    { text: 'NotEqual', value: '!=', type: [], valueField: true },
    { text: 'GreaterThan', value: '>', type: [], valueField: true },
    { text: 'GreaterThanEqual', value: '>=', type: [], valueField: true },
    { text: 'Little', value: '<', type: [], valueField: true },
    { text: 'LittleEqual', value: '<=', type: [], valueField: true },
    { text: 'DayOf', value: 'dayof', type: ['System.DateTime'], valueField: true },
    { text: 'StartsWith', value: 'starts', type: ['System.String', 'System.Number'], valueField: true },
    { text: 'EndsWith', value: 'ends', type: ['System.String', 'System.Number'], valueField: true },
    { text: 'Contains', value: 'in', type: ['System.String', 'System.Number'], valueField: true },
    { text: 'NotContains', value: 'nin', type: ['System.String', 'System.Number'], valueField: true },
    { text: 'IsEmpty', value: 'empty', type: [], valueField: false },
    { text: 'NotEmpty', value: 'nempty', type: [], valueField: false },
    { text: 'Between', value: 'between', type: ['System.DateTime'], valueField: true },
    { text: 'Today', value: 'today', type: ['System.DateTime'], valueField: false },
    { text: 'Yesterday', value: 'yesterday', type: ['System.DateTime'], valueField: false },
    { text: 'ThisHour', value: 'thishour', type: ['System.DateTime'], valueField: false },
    { text: 'ThisWeek', value: 'thisweek', type: ['System.DateTime'], valueField: false },
    { text: 'ThisMonth', value: 'thismonth', type: ['System.DateTime'], valueField: false },
    { text: 'ThisYear', value: 'thisyear', type: ['System.DateTime'], valueField: false },
    { text: 'LastHour', value: 'lasthour', type: ['System.DateTime'], valueField: false },
    { text: 'LastWeek', value: 'lastweek', type: ['System.DateTime'], valueField: false },
    { text: 'LastMonth', value: 'lastmonth', type: ['System.DateTime'], valueField: false },
    { text: 'LastYear', value: 'lastyear', type: ['System.DateTime'], valueField: false },
    { text: 'BeforeNDays', value: 'beforendays', type: ['System.DateTime'], valueField: true, valueFieldType: "System.Number" },
    { text: 'LastNDays', value: 'lastnadays', type: ['System.DateTime'], valueField: true, valueFieldType: "System.Number" },
    { text: 'LastNHours', value: 'lastnhours', type: ['System.DateTime'], valueField: true, valueFieldType: "System.Number" },
    { text: 'LastNMinutes', value: 'lastnminutes', type: ['System.DateTime'], valueField: true, valueFieldType: "System.Number" }
];