<div class="color-picker-container">
    <div class="picker-title">{{"WidgetFormPage.Color"|translate}}</div>
    <!-- <div class="favorite-colors">
        <div class="color-area" [ngClass]="{'selected-color': item.selected}" *ngFor="let item of colorList"
            (click)="selectColorFunc(item,'favorite')" [style.BackgroundColor]="item.color" [id]="item.key">
            <mat-icon style="color:#61C478;transform: scale(0.8);" *ngIf="item.selected">check_circle_outline</mat-icon>
        </div>
    </div> -->
    <div>
        <div class="picker-subtitle">
            <span>{{"ColorPaletteFormPage.ColorType"|translate}}</span>
        </div>
        <div style="display: flex;align-items: center;justify-content: space-between;">
            <div (click)="selectColorType(item)" class="icon-border" *ngFor="let item of colorTypes"
                [ngClass]="{selectedColorType: selectedColorType==item.value}">
                <mat-icon [svgIcon]="item.img"></mat-icon>
            </div>
        </div>

    </div>
    <div class="picker-subtitle">

        <span>{{"ColorPaletteFormPage.ColumnBased" | translate}}</span>



        <span>
            <mat-icon *ngIf="selectedColorType==3" (click)="dimensionColors=!dimensionColors" style="margin-right: 5px;"
                matTooltip="{{'ColorPaletteFormPage.ColumnBased'|translate}}" [svgIcon]="'dimension'"></mat-icon>
            <!-- <mat-icon *ngIf="selectedColorType!==3" (click)="colorPalettesVisible=!colorPalettesVisible"
                matTooltip="{{'ColorPaletteFormPage.ColorPalettes'|translate}}" [svgIcon]="'color-palette'"></mat-icon> -->


        </span>
    </div>

    <div *ngIf="dimensionColors">
        <mat-checkbox (change)="changeOnlyWidget()" style="font-family: Montserrat;font-size: smaller;"
            [checked]="onlyWidget">
            {{"WidgetFormPage.OnlyThisWidget"|translate}}</mat-checkbox>
    </div>
    <ng-container *ngIf="dimensionColors; else colorPalettesArea">
        <!-- Üçüncü durum: colorPalettesVisible ve dimensionColors false -->
        <!-- <mat-select [placeholder]="'Common.Select'|translate" class="color-type-select">
            <mat-option class="color-type-option" *ngFor="let item of dimensionSelectData" [value]="item.id">
                {{item.name}}
            </mat-option>
        </mat-select> -->
        <div class="table-container">
            <table class="my-table">
                <!-- Name Column -->
                <!-- <tr>
                    <th> Name </th>
                    <th> Color </th>
                </tr> -->
                <tr *ngFor="let item of dimensionTableData">
                    <td>{{item.text}}</td>
                    <td class="color-td">
                        <div [id]="'cl-'+item.text" [style.BackgroundColor]="item.color" (click)="setColorForDim(item)"
                            [popoverContext]="{ data: {multiple:false,key:item.text} }"
                            [popoverTrigger]="popoverTemplate" (popoverClosed)="onPopoverClosed()"
                            [closeOnClickOutside]="true">
                            <div *ngIf="item.color">&nbsp;</div>
                            <div *ngIf="item.color==''">
                                <mat-icon style="transform: scale(0.7);" [svgIcon]="'color-picker'"></mat-icon>
                            </div>
                        </div>


                    </td>
                </tr>
            </table>
        </div>
    </ng-container>
    <ng-template #colorPalettesArea>
        <ng-container>
            <!-- İlk durum: colorPalettesVisible true -->
            <div style="display: flex;align-items: center;justify-content: space-between;">
                <div class="icon-border" style="width: 20%;" (popoverClosed)="onPopoverClosed()"
                    [popoverTrigger]="popoverTemplate" [closeOnClickOutside]="true">
                    <mat-icon [svgIcon]="'color-picker'"></mat-icon>
                </div>
                <div *ngIf="selectedColors.length>0" (click)="clearAllSelection()" class="mini-action-button">
                    <mat-icon svgIcon="clear_all"></mat-icon>
                    <small>{{"Common.ClearAll"|translate}}</small>
                </div>
            </div>
        </ng-container>
    </ng-template>


    <div class="favorite-colors" *ngIf="!dimensionColors">
        <div *ngFor="let item of selectedColors" class="color-tag">

            <div class="color-area" [style.BackgroundColor]="item.color" [id]="item.color">
                <!-- <mat-icon style="color:#61C478;transform: scale(0.8);">check_circle_outline</mat-icon> -->
            </div>
            <div class="color-tag-text" style="display: flex;flex-direction: column;line-height: 0.9;">
                {{item.color}}
                <small>{{item.text}}</small>
            </div>
            <mat-icon class="delete-color-icon"
                (click)="selectColorFunc({color:item.color,selected:1},item.palette)">clear</mat-icon>
        </div>

    </div>
</div>





<ng-template #popoverTemplate let-data="data">
    <div class="popover-container">
        <div class="popover-header">
            <div class="title-div">
                <span class="popover-title">
                    <!-- {{"ColorPaletteFormPage.ColorPalettes" | translate}} -->
                    {{data?.key||("ColorPaletteFormPage.ColorPalettes" | translate)}}
                </span>
                <button mat-button (click)="applyColors(popoverTemplate)">Apply</button>
            </div>
            <div class="warning-text" *ngIf="warningText">
                <span>{{"ColorPaletteFormPage.WarningText" | translate}}</span>
            </div>

        </div>
        <div class="popover-content">
            <div *ngFor="let item of colorPalettes" class="color-palette">
                <span class="palette-name">{{ item.name }}</span>
                <div class="palette-colors">
                    <div *ngFor="let color of item.palettes" class="palette-color" [style.backgroundColor]="color.color"
                        (click)="selectColorFunc({color: color.color, selected: color.selected, key: data?.key}, item.colorPaletteId, data?.multiple)">
                        <mat-icon *ngIf="color.selected" class="selected-icon">check_circle_outline</mat-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>

</ng-template>