import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BussionFilterDefinition } from 'src/app/models/responses/dataStoreResponse';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { filter_options } from 'src/app/constants/filterTypes';

@Component({
  selector: 'app-filter-list',
  templateUrl: './filter-list.component.html',
  styleUrls: ['./filter-list.component.scss']
})
export class FilterListComponent implements OnInit {

  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];

  @Input() set data(value: any) {
    console.log('değişti', value)
    value = value.map(vl => {
      return { ...vl, operatorText: this.operatorOptions.find(x => x.value == vl.operator).text }
    })
    if (value) {
      this.sourceData = value;
      this.createDataTable(value);
    }
  }
  @Input() scrollarea: boolean = false
  @Input() disableDelete = false

  @Output() delete = new EventEmitter<any>();
  private sourceData = [];
  dataSource: MatTableDataSource<any>;
  operatorOptions: { text: string, value: string, type: string[], valueField: boolean, valueFieldType?: string }[] = filter_options


  constructor(
    private translate: TranslateService
  ) { }

  ngOnInit(): void {

  }

  private createDataTable(result: any[]) {
    const dataTable = result;

    this.dataSource = new MatTableDataSource(dataTable);
  }


  deleteFilter(query: any) {
    this.delete.emit(query);
    this.createDataTable(this.sourceData);
  }



}
