import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { TranslateService } from '@ngx-translate/core';
import { filter_options } from 'src/app/constants/filterTypes';
import { dateEditor } from 'src/app/core/utils';
import { Dimension } from 'src/app/models/responses/dashboardResponse';
import { BussionFilterDefinition, Columns } from 'src/app/models/responses/dataStoreResponse';
import { CoreService } from 'src/app/services/core/core.service';

@Component({
  selector: 'app-filter-form',
  templateUrl: './filter-form.component.html',
  styleUrls: ['./filter-form.component.scss']
})
export class FilterFormComponent implements OnInit {

  @Input() fields: Columns[] = [];
  searchValues = [];
  queryFilterFieldType: string = "System.String";
  selectedFilterType: string = "System.String";
  isShownFieldValueForm: boolean = true;
  requiredFieldValue: boolean = true
  rangeStart: string = ''
  rangeEnd: string = ''

  @Input() clearButton: boolean = false

  @Output() add = new EventEmitter<any>();


  queryFilter: BussionFilterDefinition = {
    fieldName: '',
    fieldValue: '',
    operator: ''
  }

  constructor(private coreService: CoreService, private translate: TranslateService) { }
  operatorOptions: { text: string, value: string, type: string[], valueField: boolean, valueFieldType?: string }[] =  filter_options.filter(x => x.type.includes(this.queryFilterFieldType) || x.type.length <= 0)


  ngOnInit(): void {

  }

  filterFieldSelected(fieldName: string) {
    this.queryFilter.fieldValue = '';
    this.queryFilter.operator = '';
    this.queryFilterFieldType = this.getSelectedFieldType(fieldName);
    this.selectedFilterType = this.getSelectedFieldType(fieldName);
    this.operatorOptions = filter_options.filter(x => x.type.includes(this.queryFilterFieldType) || x.type.length <= 0)

  }

  getSelectedFieldType(fieldName: string): string {
    if (this.fields.length > 0) {
      let field = this.fields.find(c => c.fieldName == fieldName);
      return field.fieldType;
    } else {
      return this.selectedFilterType;
    }
  }

  operatorSelected(operator: string) {
    this.queryFilter.fieldValue = '';
    this.setFieldValueFormDisplayStatus(operator);
  }

  addFilter() {

    if ((this.isShownFieldValueForm && this.queryFilter.fieldValue && this.queryFilter.operator)
      ||
      (!this.isShownFieldValueForm && this.queryFilter.fieldValue == "" && this.queryFilter.operator)) {

      this.add.emit(this.queryFilter);
      this.queryFilter = {
        fieldName: '',
        fieldValue: '',
        operator: ''
      }
      this.isShownFieldValueForm = true
    } else {
      this.coreService.showErrorMessage(this.translate.instant('Common.FormFill'));
    }

  }

  searchFilter(text: any) {

  }

  // dateSelected(date: MatDatepickerInputEvent<Date>) {
  //   var startTimeISOString = date.value.toISOString();
  //   var time = new Date(startTimeISOString);
  //   time = new Date(time.getTime() - (time.getTimezoneOffset() * 60000));
  //   this.queryFilter.fieldValue = time.toISOString();
  // }



  dateSelected(date: MatDatepickerInputEvent<Date>, range: boolean, rangeType?: string) {
    var startTimeISOString = date.value.toISOString();
    var time = new Date(startTimeISOString);

    if (range) {
      if (rangeType == 'start') {

        this.rangeStart = dateEditor(startTimeISOString, 'start')

      } else {

        this.rangeEnd = dateEditor(startTimeISOString, 'end')

        this.queryFilter.fieldValue = this.rangeStart + '/' + this.rangeEnd;
      }
    } else {
      // time = new Date(time.getTime() - (time.getTimezoneOffset() * 60000));
      this.queryFilter.fieldValue = dateEditor(startTimeISOString, 'start')
    }


    if (this.queryFilter.operator == 'between' && this.rangeStart && this.rangeEnd) {
      const [startDate, endDate] = this.queryFilter.fieldValue.split('/');
      const startFilter = {
        fieldName: this.queryFilter.fieldName,
        fieldValue: startDate,
        operator: '>'
      };
      const endFilter = {
        fieldName: this.queryFilter.fieldName,
        fieldValue: endDate,
        operator: '<'
      };
      this.add.emit(startFilter);
      this.add.emit(endFilter);

    }

  }

  private setFieldValueFormDisplayStatus(operator: string) {

    this.isShownFieldValueForm = this.operatorOptions.find(x => x.value == operator).valueField
    this.queryFilterFieldType = this.operatorOptions.find(x => x.value == operator)?.valueFieldType ? this.operatorOptions.find(x => x.value == operator)?.valueFieldType : this.getSelectedFieldType(this.queryFilter.fieldName)

    // if (this.getSelectedFieldType(this.queryFilter.fieldName) == 'System.DateTime') {
    //   if (operator == "today" ||
    //     operator == "yesterday" ||
    //     operator == "thishour" ||
    //     operator == "thisweek" ||
    //     operator == "thismonth" ||
    //     operator == "thisyear" ||
    //     operator == "lasthour" ||
    //     operator == "lastweek" ||
    //     operator == "lastmonth" ||
    //     operator == "lastyear") {
    //     this.queryFilter.fieldValue = '';
    //     this.isShownFieldValueForm = false;
    //     this.requiredFieldValue = false
    //   }
    //   else if (operator == "beforendays" ||
    //     operator == "lastnadays" ||
    //     operator == "lastnhours" ||
    //     operator == "lastnminutes" ||
    //     operator == "lastnyears") {
    //     this.queryFilterFieldType = "System.Number";
    //     this.isShownFieldValueForm = true;
    //   }
    //   else {
    //     this.queryFilterFieldType = "System.DateTime";
    //     this.isShownFieldValueForm = true;
    //   }
    // } else if (operator == "empty" ||
    //   operator == "nempty") {
    //   this.requiredFieldValue = false
    //   this.isShownFieldValueForm = false;
    // } else {
    //   this.isShownFieldValueForm = true;
    // }

  }

  clearClick() {
    this.queryFilter = {
      fieldName: '',
      fieldValue: '',
      operator: ''
    }
  }

  changeFieldType(type: string) {
    this.queryFilterFieldType = type
    this.operatorOptions = filter_options.filter(x => x.type.includes(this.queryFilterFieldType) || x.type.length <= 0)


  }

}
