import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import { PermissionService } from 'src/app/services/permission/permission.service';
import html2canvas from 'html2canvas';
import { Dashboard } from 'src/app/models/responses/dashboardResponse';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { GridsterWidget, Widget, WidgetPreview } from 'src/app/models/responses/widgetLibraryResponse';
import { BussionFilterDefinition } from 'src/app/models/responses/dataStoreResponse';
import { ScriptLoaderService } from 'src/app/services/script-loader/script-loader.service';
import { ExpandedWidgetComponent } from '../modals/expanded-widget/expanded-widget.component';
import { ModalService } from 'src/app/services/modal/modal.service';

@Component({
  selector: 'app-widget-preview',
  templateUrl: './widget-preview.component.html',
  styleUrls: ['./widget-preview.component.scss']
})
export class WidgetPreviewComponent implements OnInit, OnChanges {

  @Input() widget: GridsterWidget;
  @Input() create: boolean = false
  @Input() authType: number = 0
  // 0 No Authentication
  // 1 OTP Authentication
  // 2 Bussion Authentication

  @Output() shareEvent = new EventEmitter<any>();
  @Output() expandEvent = new EventEmitter<any>();
  @Output() openForm = new EventEmitter<any>();
  @Output() deleteWidget = new EventEmitter<any>();
  downloadLoading: boolean = false
  @Input() noDataLoading: boolean = true





  @Input() showMenu: boolean = true;
  @Input() showDelete: boolean = false;
  @Input() showTitle: boolean = true;
  @Input() showShare: boolean = false;
  @Input() showZoom: boolean = false;
  @Input() showEdit: boolean = false;
  @Input() selectedFilters: BussionFilterDefinition[] = []
  @Input() selectedDashboard: Dashboard = {
    dashboardId: '',
    title: '',
    icon: '',
    auditEnabled: 0,
    order: 1,
    dashboardStatus: 0,
    description: '',
    layoutConfig: '',
    domain: '',
    access: 0,
    owner: '',
    creationDate: '',
    updatedBy: '',
    updateDate: '',
    colorPalette: '',
    filter: [],
    widgets: [],//Widget,
    dimensions: [], //Dimension,
    messages: [], //BussionMessage
    autoRefresh: 0,
    refreshInterval: 0,
    isMainDashboard: 0,
    hideBorders: 'false',
    contentLanguage: '',
    gridSize: 0,
    tags: '',
    css: '',
    ownerId: '',
    isSelfService: 0,
    users: '',
    hideAdvancedQueryPanel: 0,
    layout: '',
    colors: []

  }

  @ViewChild('widgetContent') screen: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;

  permissions: string[] = [];
  groupedData = {};
  groupedDataKeys: string[] = []
  groupedOperatorKeys: string[] = []

  showFilter: boolean = false

  constructor(
    private permissionService: PermissionService,
    private scriptLoaderService: ScriptLoaderService,
    private modalService: ModalService
  ) {
  }

  ngOnInit(): void {
    if (this.authType !== 0) {
      this.getPermissions();
    }


    // this.scriptLoaderService.runnedWidgetCode.subscribe(res => {
    //   console.log('res', res == this.widget.designerContainer.widgetId)
    //   if (res == this.widget.designerContainer.widgetId) {
    //     this.noDataLoading = false
    //   }
    // })
  }





  ngOnChanges(changes: SimpleChanges): void {

    this.groupFilters()

    if (this.create && this.widget) {
      this.createWidget(this.widget);
    }

  }

  private createWidget(widgetResponse: GridsterWidget) {
    this.scriptLoaderService.addScriptFileToHead(widgetResponse.designerContainer.widgetLibraries);
    this.scriptLoaderService.runWidgetCode(widgetResponse.designerContainer);
  }

  groupFilters() {
    this.selectedFilters.forEach(item => {
      var visibleName = this.selectedDashboard.dimensions.find(x => x.name == item.fieldName)?.visibleName ?? item.fieldName
      if (!this.groupedData[visibleName]) {
        this.groupedData[visibleName] = {};
      }



      // this.groupedData[item.fieldName].push(item);

      if (!this.groupedData[visibleName][item.operator]) {
        this.groupedData[visibleName][item.operator] = [];
      }
      this.groupedData[visibleName][item.operator].push(item);

    });


    this.groupedDataKeys = Object.keys(this.groupedData)
  }

  getObjectKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  getPermissions() {
    this.permissionService.getUserRoleList().subscribe(permissions => {
      this.permissions = permissions;
    });
  }

  shareWidget(widgetId: string, type: string) {
    this.shareEvent.emit({ objectId: widgetId, objectType: type });
  }

  expandWidget(widgetData: any) {
    if (this.expandEvent.observers.length <= 0) {
      let data = {
        widgetData: widgetData.designerContainer,
        // shareModalOpen: (e) => this.openShareForm({ ...e, filter: this.selectedFilters }),
        selectedFilters: this.selectedFilters,
        selectedDashboard: this.selectedDashboard
      }
      this.modalService.openModal(data, ExpandedWidgetComponent, '90%', '100%', 'overflow-hidden').subscribe(result => { });
    } else {
      this.expandEvent.emit(widgetData);
    }
  }

  openWidgetForm(widget: any, type: string) {
    this.openForm.emit({ widget: widget, type: type });

  }

  removeWidget(widget: any) {
    this.deleteWidget.emit(widget);
  }

  downloadWidget(widget: any, type: string) {
    if (type == 'csv') {
      console.log('first', this.widget.designerContainer.widgetData)

      let csvData = this.jsonToCsv(this.widget.designerContainer.widgetData); // Add .items.data
      // Create a CSV file and allow the user to download it
      let blob = new Blob([csvData], { type: 'text/csv' });
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      a.href = url;
      a.download = widget.designerContainer.widget.title + '.csv';
      document.body.appendChild(a);
      a.click();
    } else {
      html2canvas(this.screen.nativeElement).then(canvas => {
        this.canvas.nativeElement.src = canvas.toDataURL();
        this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
        this.downloadLink.nativeElement.download = widget.designerContainer.widget.title + '.png';
        this.downloadLink.nativeElement.click();


      }).finally(() => {

        this.downloadLoading = false

      });
    }
  }

  jsonToCsv(jsonData) {
    let csv = '';
    // Get the headers
    let headers = Object.keys(jsonData[0]);
    csv += headers.join(',') + '\n';
    // Add the data
    jsonData.forEach(function (row) {
      let data = headers.map(header => JSON.stringify(row[header])).join(','); // Add JSON.stringify statement
      csv += data + '\n';
    });
    return csv;
  }

  DownloadFilter(checkbox) {
    this.showFilter = checkbox.checked
  }



}
