import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ColorPalette } from 'src/app/models/responses/colorPaletteResponse';
import { BussionFilterDefinition } from 'src/app/models/responses/dataStoreResponse';
import { ColorItem } from 'src/app/models/responses/widgetLibraryResponse';
import { ColorPaletteService } from 'src/app/services/color-palette/color-palette.service';
import { ScriptLoaderService } from 'src/app/services/script-loader/script-loader.service';



interface ModifiedColorPalette extends Omit<ColorPalette, 'palettes'> {
  palettes: { color: string; selected: number }[];
}

@Component({
  selector: 'app-custom-color-picker',
  templateUrl: './custom-color-picker.component.html',
  styleUrls: ['./custom-color-picker.component.scss']
})
export class CustomColorPickerComponent implements OnInit {
  @Input() colorList: { color: string, key: string, selected?: boolean }[] = [
    { color: 'red', key: '1', selected: false },
    { color: 'blue', key: '2', selected: false },
    { color: 'purple', key: '3', selected: false },
    { color: 'yellow', key: '4', selected: false },
    { color: 'green', key: '5', selected: false },
    { color: 'red', key: '6', selected: false },

  ]
  // @Input() dimension: BussionFilterDefinition[] = []
  _widgetData
  @Input() set widgetData(data: { data: any[], dimension: BussionFilterDefinition[], colors: { type: number, onlyWidget: number, colors: ColorItem[], dashboardWidgetColors: ColorItem[] } }) {

    this._widgetData = data
    this.selectedColorType = data.colors.type




    this.selectedColors = JSON.parse(JSON.stringify(data.colors.colors))



    this.onlyWidget = data.colors.onlyWidget == 1 ? true : false


    var item = data.dimension[data.dimension.length - 1]

    data.data.map((dt, key) => {
      if (!this.dimensionTableData.some(x => x.text == dt[item.fieldName]))
        this.dimensionTableData.push({ text: dt[item.fieldName], palette: '', color: data.colors.colors.find(x => x.text == dt[item.fieldName])?.color || '' })
      else
        this.dimensionTableData.find(x => x.text == dt[item.fieldName]).color = data.colors.colors.find(x => x.text == dt[item.fieldName])?.color || '';
    })



  }
  displayedColumns: string[] = ['name', 'color']
  dimensionTableData: ColorItem[] = []
  @Input() selectedColorsForUpdate: { type: number, colors: ColorItem[] } = { type: 0, colors: [] }

  colorTypes = [
    { type: 'samecolor', img: 'samecolor', value: 0 },
    { type: 'highttolow', img: "highttolow", value: 1 },
    { type: 'lowtohigh', img: "lowtohigh", value: 2 },
    { type: 'multicolor', img: "multicolor", value: 3 },
    // { type: 'specialColor', img: "multicolorcustom", value: 4 },
  ]

  selectedColorType: number = 0


  @Output() selectColor: EventEmitter<any> = new EventEmitter<any>()
  @Output() getPaletColors: EventEmitter<any> = new EventEmitter<any>()




  rgbForm = new FormGroup({
    r: new FormControl('', [Validators.required]),
    g: new FormControl('', [Validators.required]),
    b: new FormControl('', [Validators.required]),
  });

  hexForm = new FormGroup({
    hex: new FormControl('', [Validators.required]),
  });

  createdColors: { color: string, key: string, selected?: boolean }[] = []

  colorType: string = 'rgb'
  colorPalettesVisible: boolean = true
  dimensionColors: boolean = false

  colorPalettes: ModifiedColorPalette[] = []

  selectedColorPalettes: ColorPalette[] = []
  selectedPaletColors: { paletId: string, color: string, key: string }[] = []
  selectedColors: ColorItem[] = []
  onlyWidget: boolean = false
  warningText: boolean = false

  constructor(private colorPaletteService: ColorPaletteService, private scriptLoader: ScriptLoaderService
  ) { }

  ngOnInit(): void {
    this.getColorPalettes()


  }






  getColorPalettes() {
    this.colorPaletteService.getColorPalettes().subscribe(res => {
      this.colorPalettes = res.map(item => {
        return {
          ...item, palettes: item.palettes.map(pitem => {
            return { color: pitem, selected: 0 }
          })
        }
      })

      // if (this.selectedColorsForUpdate)
      //   this.SetForUpdate()
    })
  }

  allColorDeselect() {
    this.colorPalettes.forEach(palette => {
      palette.palettes.forEach(color => {
        if (color.selected === 1) {
          color.selected = 0;
        }
      });
    });
  }


  selectColorFunc(selectedcolor: { color: string; selected: number, key?: string }, colorPaletteId: string, multiple?: boolean) {
    this.warningText = false
    if (multiple == null) {
      if (this.selectedColorType == 0 || this.selectedColorType == 1 || this.selectedColorType == 2) {
        multiple = false
      } else {
        multiple = true
      }
    }


    if (selectedcolor.selected == 1) {
      if (colorPaletteId)
        this.colorPalettes.find(x => x.colorPaletteId == colorPaletteId).palettes.find(x => x.color == selectedcolor.color).selected = 0

      this.selectedColors = this.selectedColors.filter(x => !(x.palette == colorPaletteId && x.color == selectedcolor.color))
      this.applyColors()
    } else {
      //bunlarda tek color seçmek lazım bir seçim olduğunda diğeri iptal edilir
      if (!multiple) {
        this.allColorDeselect()
        if (!selectedcolor.key)
          this.selectedColors = []
      }
      if (selectedcolor.key) {
        this.dimensionTableData.find(x => x.text == selectedcolor.key).color = selectedcolor.color
        this.selectedColors = this.selectedColors.filter(x => x.text !== selectedcolor.key)
      }

      if (this.selectedColors.length >= this.dimensionTableData.length) {
        this.warningText = true
        return
      }


      const dim = this.dimensionTableData.find(item =>
        !this.selectedColors.some(x => x.text == item.text)
      );

      var colorText = selectedcolor.key || dim.text
      this.selectedColors.push({ palette: colorPaletteId, color: selectedcolor.color, text: colorText })


      this.colorPalettes.find(x => x.colorPaletteId == colorPaletteId).palettes.find(x => x.color == selectedcolor.color).selected = 1

    }

  }


  applyColors(popover?: any) {
    if (!this.dimensionColors) {
      this.onlyWidget = true
    }
    this.selectColor.emit({ selectedColors: this.selectedColors, colorType: this.selectedColorType, onlyWidget: this.onlyWidget })

  }

  selectColorType(item) {

    this.selectedColorType = item.value
    if (this.selectedColorType !== 3) {
      this.dimensionColors = false
      this.selectedColors = this.selectedColors.length > 0 ? [this.selectedColors[0]] : []

    } else {
      this.selectedColors = this._widgetData.dashboardWidgetColors
    }

    this.applyColors()
  }



  sendColorPaletForLoader() {
    var visibleColors = []
    this.selectedColorPalettes.map(palet => {
      return palet.palettes.map(cl => {
        return visibleColors.push({ paletId: palet.colorPaletteId, color: cl, key: palet.colorPaletteId })
      })
    })

    this.getPaletColors.emit(visibleColors)
  }




  setColorForDim(item: ColorItem) {
    this.warningText = false
    this.allColorDeselect();
    this.colorPalettes.map(cl => {
      if (cl.palettes.find(x => x.color == item.color)) {
        cl.palettes.find(x => x.color == item.color).selected = 1
      }
    })
  }

  onPopoverClosed() {

    var widgetColorText: string = this._widgetData.colors.colors.map(cl => {
      return cl.color;
    }).join(',')

    var selectedColorText = this.selectedColors.map(cl => {
      return cl.color;
    }).join(',')

    if (selectedColorText !== widgetColorText) {
      this.applyColors()
    }


  }
  clearAllSelection() {
    this.selectedColors = []
  }

  changeOnlyWidget() {
    this.onlyWidget = !this.onlyWidget
    this.applyColors()
  }



}

